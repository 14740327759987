import {Dispatch, useRef} from "react";
import {HeadsetInputReducerAction} from "./reducer";
import {useDebouncedCallback} from "../../helpers";

/**
 * Bitwise operations for YeaLink Phones
 */
export const yeaLinkOperations = {
    offHook: 0b0001,
    onHook: 0b0000,
    mute: 0b0010,
    unmute: 0b0000,
    ring: 0b0100,
    ringOff: 0b0000,
    hold: 0b1000,
    resume: 0b0001,
};

export type YeaLinkOps = keyof typeof yeaLinkOperations;

interface Props {
    reducer: Dispatch<HeadsetInputReducerAction>;
}

export const useConnectedHeadsets = ({reducer}: Props) => {
    const inputReportRef = useRef<number[]>([]);
    const sendReport = useRef<boolean>(false);
    const previousReportRef = useRef<number[]>([]);  // Ref to store the previous array

    const handleDebouncedReports = useDebouncedCallback(() => {
        if (inputReportRef.current.length > 0) {
            const previousArray = previousReportRef.current;
            const currentArray = inputReportRef.current;

              if(currentArray[1] === 0 && currentArray[0] === 1) {
                reducer({
                    type: 'newReport',
                    payload: inputReportRef.current[1],
                });
            }else if(previousArray[0] === 9 && currentArray[0] === 1 && currentArray[1] === 59){
                  reducer({
                      type: 'newReport',
                      payload: 5
                  });
              }
              else if(currentArray[1] === 5 && currentArray[0] === 1 && inputReportRef.current[2] === 1){
                  reducer({
                      type: 'newReport',
                      payload: inputReportRef.current[1],
                  });
              }
              else{
                  reducer({
                      type: 'newReport',
                      payload: inputReportRef.current[0],
                  });
              }
        }
        previousReportRef.current = [...inputReportRef.current];

        inputReportRef.current = [];
    }, [],300);

    const filters = [
        {
            usage: 0x0005,
            usagePage: 0x000B,
        },
    ];

    /**
     * Consent step for user to allow app to access a device
     * TODO - Create display handler in electron
     */
    async function requestDevice() {
        try {
            const [device] = await navigator.hid.requestDevice({filters})

            if (!device) {
                console.warn('Invalid device selected')
                return;
            }

            connectDevice(device)
                .catch(() => {
                    console.warn("Failed to connect to device")
                })
        } catch (e) {
            console.error("Failed getting hid headset")
        }
    }

    /**
     * When connecting a device, send a blank report to test connection
     * Then add event handler to watch for headset inputs
     * @param device
     */
    async function connectDevice(device: HIDDevice) {
        device.open()
            .then(() => {
                const value = 0x00;

                /**
                 * If the device does not accept reports then don't
                 * send after connection
                 */
                device
                    .sendReport(0x02, new Uint8Array([value]))
                    .then(() => {
                        sendReport.current = true;
                    })
                    .catch(() => {
                        sendReport.current = false;
                    })
                    .finally(() => {
                        device.removeEventListener('inputreport', handleInputReport);
                        device.addEventListener('inputreport', handleInputReport);
                        reducer({
                            type: "setHeadset",
                            payload: device,
                        });
                    });
            })
            .catch(() => {
                console.warn('Failed connecting device');
            });
    }

    /**
     * Decode button press from headset, debounce to handle double clicks
     * @param event
     */
    function handleInputReport(event: HIDInputReportEvent) {
        const {data} = event;

        const value = data.getUint8(0);

        if (value >= 0 && value <= 59) {
            inputReportRef.current.push(value);
            handleDebouncedReports();
        }
    }

    /**
     * Build the bitwise request to send to device based on available options
     * @param opList
     * @param device
     */
    function handleOperation(opList: YeaLinkOps[], device?: HIDDevice) {
        if (!sendReport.current || !device) return;

        let bitwiseOperation = 0b0000;

        opList.forEach((operation) => {
            bitwiseOperation += yeaLinkOperations[operation];
        });

        device.sendReport(0x02, new Uint8Array([bitwiseOperation])).then(() => {
            // console.log(`Send Report "${bitwiseOperation}" To "${device.productName}" HID device`);
        });
    }

    return {
        requestDevice,
        handleOperation,
        connectDevice,
    };
};

// Event listeners for HID connect/disconnect
navigator.hid.addEventListener('connect', (event) => {
    console.log('Automatically open event.device or warn user a device is available');
});

navigator.hid.addEventListener('disconnect', (event) => {
    console.log('Remove |event.device| from the UI.');
});
